import React from 'react'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import devContent from './../dev.json'
import { css, StyleSheet } from 'aphrodite'
import PropTypes from 'prop-types'

export default function LoadingBar({ amount }) {
  const bar = useSpring({
    width: amount + '%',
  })
  return (
    <div className={css(styles.loadingBarBackground)}>
      <animated.div style={bar} className={css(styles.loadingBar)} />
    </div>
  )
}

LoadingBar.propTypes = {
  amount: PropTypes.number,
}

const styles = StyleSheet.create({
  loadingBar: {
    backgroundColor: devContent.Loader.progressBar.color,
    height: devContent.Loader.progressBar.height,
  },
  loadingBarBackground: {
    backgroundColor: devContent.Loader.progressBar.backgroundColor,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
})
